import React from "react";

import * as actions from "./ExtraLibraryActionType";

const ExtraLibraryContext = React.createContext();

const initState = {
  hello: "ExtraLibraryProvider",
  //
  extra_courseIndexTracking: [],
};
// Media
const setExtra_courseIndexTracking = (mediaViewBy, state) => {
  return { ...state, extra_courseIndexTracking: mediaViewBy };
};

const ExtraLibraryReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.AT_SET_EXTRA_COURSE_INDEX_TRACKING:
      return setExtra_courseIndexTracking(action.payload, state);

    //
    default:
      return state;
  }
};

const ExtraLibraryProvider = ({ children }) => {
  const [state_ExtraLC, dispatch_ExtraLC] = React.useReducer(
    ExtraLibraryReducer,
    initState
  );
  const value = { state_ExtraLC, dispatch_ExtraLC };
  return (
    <ExtraLibraryContext.Provider
      value={value}
      displayName="ExtraLibraryProvider"
    >
      {children}
    </ExtraLibraryContext.Provider>
  );
};

const useExtraLibraryConsumer = () => {
  const context = React.useContext(ExtraLibraryContext);
  if (context === undefined) {
    throw new Error(
      "useExtraLibrary must be used within a ExtraLibraryProvider"
    );
  }
  return context;
};

export { ExtraLibraryProvider, useExtraLibraryConsumer };
